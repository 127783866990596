import {getConfig} from "@/api/erp/ConfigApi";
import {CONFIG_NAME, CONFIG_NAME_ACTIVITY} from "@/model/ConfigModel";

/**
 * 配置model
 */
class ConfigModel {
    // 获取配置
    static async getConfig(key, field) {
        let [data] = await getConfig(key, field);
        return data.data;
    }

    // 获取首页配置
    static async getIndexConfig() {
        let data = await ConfigModel.getConfig(CONFIG_NAME, "indexManage")
        let indexManage = JSON.parse(data)
        return indexManage
    }

    // 获取产品页配置
    static async getProductConfig() {
        let data = await ConfigModel.getConfig(CONFIG_NAME, "productManage")
        let productManage = JSON.parse(data)
        return productManage
    }

    // 获取新闻页配置
    static async getNewsConfig() {
        let data = await ConfigModel.getConfig(CONFIG_NAME, "newsManage")
        let newsManage = JSON.parse(data)
        return newsManage
    }

    // 获取关于我们配置
    static async getAboutConfig() {
        let data = await ConfigModel.getConfig(CONFIG_NAME, "aboutManage")
        return JSON.parse(data)
    }

    // 获取活动页配置
    static async getActivityConfig(activityName) {
        let data = await ConfigModel.getConfig(CONFIG_NAME_ACTIVITY, activityName)
        let activity = JSON.parse(data)
        return activity
    }
}

export {ConfigModel}
