<template>
  <div class="component-container">
    <div class="footer-container flex flex-dr flex-center">
      <div class="flex flex-start top">
        <img :src="infoManage.logos.pc_logo_bottom" alt="" class="logo">
        <span v-if="infoManage.qqList.length>0">QQ：<b v-for="qq in infoManage.qqList" style="font-weight: normal;margin-right: 10px">{{qq.value}}</b></span>
        <span>电话：<b v-for="phone in infoManage.phoneList" style="font-weight: normal;margin-right: 10px">{{phone.value}}</b></span>
      </div>
      <div class="navs">
        <div class="nav-box flex flex-between">
          <a href="/">首页</a>
          <a href="/product">产品中心</a>
          <a href="/about">关于{{ infoManage.companyNameShort }}</a>
          <a href="/?hash=cop" v-if="copManage.show">渠道合作</a>
          <a :href="infoManage.experimentUrl" target="_blank">思政虚拟仿真体验教学中心</a>
        </div>
      </div>
      <div class="address">
        地址：{{ infoManage.companyAddress }}
      </div>
      <div class="copyright">
        <span style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">{{ infoManage.beiAnNumber }}</span> 版权所有©️{{ infoManage.companyName }}
      </div>
    </div>
  </div>
</template>

<script>
import {ConfigModel} from "@/model/erp/ConfigModel";
import {findObjectArrSameItemForArr} from "@/utils/common"
import {WebAnalyticsModel} from "../../model/WebAnalyticsModel";

export default {
  name: "footerComponent",
  data() {
    return {
      window:window,
      infoManage: {
        logos: {},
        contactObj: {},
        qqList: [],
        phoneList: []
      },
      copManage:{}
    }
  },
  async mounted() {
    let indexManage = await ConfigModel.getIndexConfig();
    indexManage.infoManage.qqList= findObjectArrSameItemForArr(indexManage.infoManage.contactList, "type", "qq")
    indexManage.infoManage.phoneList= findObjectArrSameItemForArr(indexManage.infoManage.contactList, "type", "phone")
    this.infoManage = indexManage.infoManage
    this.copManage = indexManage.copManage
    if (this.$route.query.hash) {
      setTimeout(() => {
        location.hash = this.$route.query.hash
      }, 350)
    }

    // 网站统计
    WebAnalyticsModel.init()
  }
}
</script>

<style scoped lang="less">
.footer-container {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  height: 269px;
  border: 1px solid #707070;

  .top {
    margin-bottom: 40px;

    .logo {
      width: 197px;
      height: 18px;
      display: inline-block;
    }

    span {
      display: inline-block;
      margin-left: 32px;
    }
  }


  .navs {
    width: 100%;
    border: 1px solid rgba(228, 228, 228, 0.15);
    border-right: none;
    border-left: none;
    height: 55px;

    .nav-box {
      width: 1000px;
      margin: 0 auto;

      a {
        text-decoration: none;
        font-weight: 400;
        color: #fff;
        line-height: 55px;
      }
    }
  }

  .address {
    margin-top: 20px;
  }

  .copyright {
    margin-top: 8px;
  }
}
</style>
