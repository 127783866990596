<template>
  <div id="app">
    <!--页首-->
    <header-component></header-component>
    <!--页中-->
    <router-view/>
    <!--页尾-->
    <footer-component></footer-component>
  </div>
</template>
<script>
import headerComponent from "@/components/pc/header";
import footerComponent from "@/components/pc/footer";

export default {
  components:{headerComponent,footerComponent},
}
</script>

<style lang="less">
</style>
