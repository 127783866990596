/**
 * element自定义组件
 */
import {Message} from 'element-ui'
import {MessageBox} from 'element-ui';
import fa from "element-ui/src/locale/lang/fa";

// 错误信息弹出框
export function msg_err(msg) {
  Message({
    message: msg,
    type: 'error',
    duration: 5 * 1000
  });
}

// 成功消息弹出框
export function msg_success(msg) {
  Message({
    message: msg,
    type: 'success',
    duration: 5 * 1000
  });
}


// 输入弹出框
export function msg_input(title, des, value) {
  return new Promise(function (resolve, reject) {
    // 弹出输入框
    MessageBox.prompt(des, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputValue: value,
    }).then(({value}) => {
      resolve(value);
    }).catch(() => {
      reject();
    });
  });
}

// 确认弹出框
export function msg_confirm(title) {
  return new Promise(function (resolve, reject) {
    MessageBox.confirm(title, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      resolve(true);
    }).catch(() => {
      resolve(false);
    });
  });
}

// 确认弹出框-选择左右选项不同
export function msg_confirm_choose(des, title, leftText, rightText) {
  return new Promise(function (resolve, reject) {
    MessageBox.confirm(des, title, {
      distinguishCancelAndClose: true,
      confirmButtonText: rightText,
      cancelButtonText: leftText,
    }).then(() => {
      resolve('right');
    }).catch((action) => {
      if (action === 'cancel') {
        resolve('left');
      } else {
        resolve(false)
      }
    });
  });
}
