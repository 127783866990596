import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        // 首页
        path: '/',
        name: "Index",
        component: () => import('@/views/home')
    },
    {
        // 产品中心
        path: '/product',
        name: "Product",
        component: () => import('@/views/product')
    },
    {
        // 新闻列表
        path: '/newsList',
        name: "newsList",
        component: () => import('@/views/news/list')
    },
    {
        // 新闻详情
        path: '/newsView',
        name: "newsView",
        component: () => import('@/views/news/view')
    },
    {
        // 关于我们页
        path: '/about',
        name: "about",
        component: () => import('@/views/about')
    },
    // 活动页-54活动-主页
    {
        path: '/dsstudy',
        name: 'activity54Study',
        component: () => import('@/views/activity/54study/index.vue')
    },
    // 版本管理-实验包下载
    {
        path: '/experimentPackageDownload',
        name: 'experimentPackageDownload',
        component: () => import('@/views/versionManager/experimentPackageDownload')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
