import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 设置是不是在首页
    isIndex:false,
  },
  mutations: {
    SET_ISINDEX:(state,isindex)=>{
      state.isIndex=isindex
    }
  },
  actions: {
    setIndex({ commit },v) {
      commit('SET_ISINDEX',v)
    },
  },
  modules: {
  }
})
