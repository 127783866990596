/**
 * 网站统计model
 * 前端，统计页面的各个功能
 */
import axios from "axios";
import {API_URL_ERP} from "./ConfigModel";

class WebAnalyticsModel {
    static init() {
        (function () {
            // 获取页面id，仅用于标记此次页面
            let pageId = getUUID()
            // 获取或生成sessionId，用于标记会话
            let sessionId = getSessionId()
            // 获取或生成当日cookieId,用于标记UV
            let cookieId = getCookieId()
            // 获取或生成用户id，用于标记用户，原理是一个长期cookie
            let userId = getUserId()
            // 获取浏览器通用数据-enter
            getCommonInfo()
            // 计算页面停留时间-leave
            calStayTime()
            // 监听所有带有webAnalytics-event属性的点击事件 event
            document.addEventListener('click', eventHandler)

            // 计算页面停留时间
            function calStayTime() {
                let startTime = new Date().getTime();
                window.addEventListener('beforeunload', () => {
                    const endTime = new Date().getTime();
                    const duration = endTime - startTime;
                    let params = {
                        stayTime: duration,
                    }
                    params.pageId = pageId
                    //调用接口发送统计信息
                    axios.post(API_URL_ERP + "/v1/webAnalytics/001/leave", params).then(res => {
                    })
                });
            }

            // 通用事件监听
            function eventHandler(event) {
                const target = event.target;
                if (!target.hasAttribute("data-event-info")) {
                    return
                }
                let params = JSON.parse(target.getAttribute("data-event-info"))
                params.pageId = pageId
                params.sessionId = sessionId
                params.cookieId = cookieId
                params.userId = userId
                params.url = document.URL
                //调用接口发送统计信息
                axios.post(API_URL_ERP + "/v1/webAnalytics/001/event", params).then(res => {
                })
            }


            // 浏览器通用数据收集
            function getCommonInfo() {
                let params = {};
                params.pageId = pageId
                params.sessionId = sessionId
                params.cookieId = cookieId
                params.userId = userId
                //Document对象数据
                if (document) {
                    params.domain = document.domain || '';
                    params.url = document.URL || '';
                    params.title = document.title || '';
                    params.referrer = document.referrer || '';
                }
                //Window对象数据
                if (window && window.screen) {
                    params.sh = window.screen.height || 0;
                    params.sw = window.screen.width || 0;
                    params.cd = window.screen.colorDepth || 0;
                }
                //navigator对象数据
                if (navigator) {
                    params.lang = navigator.language || '';
                }
                let userAgentInfo = getUserAgentInfo()
                params = {...params, ...userAgentInfo}
                params.stayTime = 0
                //拼接参数串
                let args = '';
                for (let i in params) {
                    if (args !== '') {
                        args += '&';
                    }
                    args += i + '=' + (params[i]);
                }
                console.log(args)
                //调用接口发生统计信息
                axios.post(API_URL_ERP + "/v1/webAnalytics/001/enter", params).then(res => {
                })
            }

            // userAgent信息判断
            /**
             * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36
             *
             */
            function getUserAgentInfo() {
                const userAgent = navigator.userAgent
                // 操作系统
                let os = 'unknown';
                if (userAgent.indexOf('Windows') !== -1) {
                    os = 'Windows';
                } else if (userAgent.indexOf('Mac') !== -1) {
                    os = 'macOS';
                } else if (userAgent.indexOf('Linux') !== -1) {
                    os = 'Linux';
                } else if (userAgent.indexOf('Android') !== -1) {
                    os = 'Android';
                } else if (userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
                    os = 'iOS';
                }
                // 浏览器
                let browser = 'unknown';
                if (userAgent.indexOf('Chrome') !== -1) {
                    browser = 'Chrome';
                } else if (userAgent.indexOf('Firefox') !== -1) {
                    browser = 'Firefox';
                } else if (userAgent.indexOf('Safari') !== -1) {
                    browser = 'Safari';
                } else if (userAgent.indexOf('Edge') !== -1) {
                    browser = 'Edge';
                } else if (userAgent.indexOf('Trident') !== -1) {
                    browser = 'Internet Explorer';
                }
                if (userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
                    browser = 'WeiXin';
                }
                // 是否是手机
                const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
                // 手机类型
                let mobileType = '';
                if (userAgent.indexOf('iPhone') !== -1) {
                    mobileType = 'iPhone';
                } else if (userAgent.indexOf('iPad') !== -1) {
                    mobileType = 'iPad';
                } else if (userAgent.indexOf('Android') !== -1) {
                    mobileType = 'Android';
                }
                // 安卓手型号
                let androidBand = ""
                const brandRegex = /;\s?(.*(?:Build))/i;
                const match = userAgent.match(brandRegex);
                if (match && match.length > 1) {
                    androidBand = match[1].split(';')[0].trim();
                }
                return {
                    os, browser, isMobile, mobileType, androidBand
                }
            }

            /**
             * 获取sessionId
             * 如果不存在就创建
             */
            function getSessionId() {
                let sessionId = sessionStorage.getItem("webAnalytics_sessionId")
                if (!sessionId) {
                    sessionId = getUUID()
                    sessionStorage.setItem("webAnalytics_sessionId", sessionId)
                }
                return sessionId
            }

            /**
             * 获取cookieId
             * 如果不存在就创建到今日23时59分59秒过期的cookie
             */
            function getCookieId() {
                let cookieId = getCookie("webAnalyticsCookieId")
                if (!cookieId) {
                    cookieId = getUUID()
                    const now = new Date();
                    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
                    const expires = `expires=${endOfDay.toUTCString()}`;
                    document.cookie = `webAnalyticsCookieId=${cookieId};${expires};path=/`;
                }
                return cookieId
            }

            /**
             * 获取userId
             * 如果不存在就创建,一个长期cookie
             */
            function getUserId() {
                let userId = getCookie("webAnalyticsUserId")
                if (!userId) {
                    userId = getUUID()
                    const now = new Date();
                    const endOfDay = new Date(now.getFullYear() + 99, now.getMonth(), now.getDate(), 23, 59, 59);
                    const expires = `expires=${endOfDay.toUTCString()}`;
                    document.cookie = `webAnalyticsUserId=${userId};${expires};path=/`;
                }
                return userId
            }

            // 创建uuid
            function getUUID() {
                let s = []
                let hexDigits = '0123456789abcdef'
                for (let i = 0; i < 36; i++) {
                    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
                }
                s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
                s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
                s[8] = s[13] = s[18] = s[23] = '-'
                return s.join('')
            }

            // 获取一个cookie
            function getCookie(name) {
                const nameWithEquals = name + "=";
                const decodedCookie = decodeURIComponent(document.cookie);
                const cookieArray = decodedCookie.split(';');
                for (let i = 0; i < cookieArray.length; i++) {
                    let cookie = cookieArray[i].trim();
                    if (cookie.indexOf(nameWithEquals) === 0) {
                        return cookie.substring(nameWithEquals.length, cookie.length);
                    }
                }
                return "";
            }
        })();
    }

}

export {WebAnalyticsModel}
